export function getParameterByName (name, url) {
  if (!url) {
    url = window.location.href
  }

  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) return null
  if (!results[2]) return ''

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export function getZ () {
  if (window.innerWidth >= 1435) {
    return 4.5
  } else if (window.innerWidth >= 1150) {
    return 5.5
  } else if (window.innerWidth >= 1000) {
    return 6.5
  } else {
    return 12
  }
}

export function createDataTexture ({
  data,
  tWidth,
  tHeight,
  format,
  filterType
}) {
  const dataTexture = new THREE.DataTexture(
    data,
    tWidth,
    tHeight,
    format,
    THREE.FloatType
  )

  dataTexture.minFilter = dataTexture.magFilter = filterType
  dataTexture.needsUpdate = true
  dataTexture.flipY = false

  return dataTexture
}
