import isWebglEnabled from 'detector-webgl'

import Camera from './io/camera'
import Renderer from './io/renderer'
import Stats from './io/stats'

import Scene from './objects/scene'
import Particles from './objects/particles'
import { getZ } from './utils'

import '../sass/main.sass'

import '../images/star.png'
import '../images/icon.png'
import '../images/tuqire-icon-144x144.png'
import '../images/tuqire-icon-192x192.png'

function getParameterByName (name, url) {
  if (!url) {
    url = window.location.href
  }

  name = name.replace(/[\[\]]/g, '\\$&') // eslint-disable-line no-useless-escape
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) return null
  if (!results[2]) return ''

  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const isNotMobileScreen = () => window.matchMedia('(min-width: 480px)').matches
const isTabletScreen = () => window.matchMedia('(max-width: 1000px)').matches

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('stars-simulation-container')

  if (container && isWebglEnabled && isNotMobileScreen()) {
    const WIDTH = window.innerWidth
    const HEIGHT = window.innerHeight
    const renderer = new Renderer({ width: WIDTH, height: HEIGHT, container })
    const stats = new Stats()
    const particles = new Particles({
      renderer,
      numParticles: isTabletScreen() ? 40000 : 120000,
      radius: 4,
      minSize: 0.015,
      maxSize: 0.03,
      sizeRange: 0.003,
      sizeInc: 0.00005,
      skew: 35,
      hoverDist: 0.04,
      hoverSizeInc: 0.003,
      hoverMaxSizeMultiplier: 1.7,
      font: 'arial',
      fontSize: 80,
      topSpeed: 1,
      acceleration: 0.0001,
      textAlign: 'left',
      textSizeMultiplier: 600,
      textPositionMultiplier: 5.5,
      brightness: 0.9,
      opacity: 1
    })
    const camera = new Camera({
      particles,
      position: {
        x: 0,
        y: -0.001,
        z: getZ()
      }
    })
    const scene = new Scene()
    const MILLISECONDS_PER_FRAME = 16.666
    let prevDelta = 0

    const init = () => {
      scene.add(particles.get())

      if (getParameterByName('stats') === 'true') {
        container.appendChild(stats.getDomElement())
      }
    }

    const animate = currDelta => {
      const delta = currDelta - prevDelta

      prevDelta = currDelta

      render(MILLISECONDS_PER_FRAME / delta)

      window.requestAnimationFrame(animate)
    }

    const render = delta => {
      particles.update(delta)

      stats.update()

      renderer.render({
        scene: scene.get(),
        camera: camera.get()
      })
    }

    init()
    window.requestAnimationFrame(animate)
  } else {
    console.warn('webgl not supported')
  }
})
